import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import TGModal from "../../../../../../shared/tg-modal";
import TGIcon from "../../../../../../shared/tg-icon";
import { useNavigate } from "react-router-dom";
import styles from "../route-flight-listing.module.scss";
import useScreenSize from "../../../../../../utils/hook/useScreenSize";

const ModalPopus = () => {
  const isDesktopView: boolean = useScreenSize().deviceSize.width > 767;
  const { t, i18n } = useTranslation();
  const navigate = useNavigate();
  const [show, setShow] = useState(true);

  const handleClose = () => {
    setShow(false);
  };

  const onClickOKButton = () => {
    handleClose();
    navigate(`/${i18n?.language}`);
  };

  return (
    <>
      <TGModal
        show={show}
        handleClose={handleClose}
        buttonLabel="Ok"
        customModalClass={styles.customModalClass}
        customOffCanvasClass={styles.customOffCanvasClass}
        centered={true}
        backdrop="static"
      >
        <div className={styles.cancelRedeemDiv}>
          <div className={styles.cancelRedeemContent}>
            <div className={styles.iconModalIllustration}>
              <div className={styles.groupIcons}>
                <div className={styles.iconlayout}>
                  <TGIcon
                    icon="review-info-modal-layout-icon"
                    fillColor=""
                    size="10px"
                  />
                </div>
              </div>
            </div>
            <div className={styles.textLayout}>
              <div className={styles.cancelRedeem}>
                <span className={styles.cancleThisRedeemLabel}>
                  {t("label_total_miles_exceeded")}
                </span>
              </div>
              <span className={styles.cancelConfirmationLabel}>
                {t("label_dont_have_enought_miles")}
              </span>
            </div>
          </div>
          {isDesktopView && (
            <div className={styles.closeIcon} onClick={handleClose}>
              <TGIcon
                icon="review-info-cancel-close-icon"
                fillColor=""
                size="10px"
              />
            </div>
          )}
        </div>
        <div className={styles.stayAndCancelBtnDiv}>
          <div className={styles.stayBtnDiv}>
            <button className={styles.stayButton} onClick={onClickOKButton}>
              <span className={styles.stayTextLabel}>Close</span>
            </button>
          </div>
        </div>
      </TGModal>
    </>
  );
};

export default ModalPopus;
