import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import RouteFlightListing from "../route-flight-listing";
import RouteFlightListingMobile from "../route-flight-listing/route-flight-listing-mobile";
import SelectFlightSummary from "../select-flight-summary";
import { RootState } from "../../../slice/RootReducer";
import Redemption from "../index";
import { useLocation } from "react-router-dom";
import useScreenSize from "../../../../../utils/hook/useScreenSize";
import { fetchCountryRequest } from "../../../slice/fetchProfileSlice";

const SelectFlight: React.FC = () => {
  const dispatch = useDispatch();
  const flightDetails = useSelector(
    (state: RootState) => state?.osciReducer?.flightInfo?.redemptionBookingData
  );

  const isDesktopView: boolean = useScreenSize().deviceSize.width > 767;
  const isMobile = useScreenSize()?.deviceSize?.width < 768;
  const { pathname } = useLocation();
  // Automatically scrolls to top whenever pathname changes
  useEffect(() => {
    window.scrollTo(0, 0);
    dispatch(fetchCountryRequest());
  }, [pathname]);

  return (
    <>
      <Redemption />
      {isDesktopView && <SelectFlightSummary flightDetails={flightDetails} />}

      {isDesktopView && <RouteFlightListing flightDetails={flightDetails} />}
      {isMobile && <RouteFlightListingMobile flightDetails={flightDetails} />}
    </>
  );
};

export default SelectFlight;
