import React, { SetStateAction, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import moment from "moment";
import useScreenSize from "../../../../../../utils/hook/useScreenSize";
import styles from "../route-flight-listing.module.scss";
import FlightCardMobile from "../../flight-card/flight-card-mobile";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../../../slice/RootReducer";
import DateSelectionWidget from "../../date-selection-widget";
import { useNavigate } from "react-router";
import {
  disable_continue_btn,
  saveRedemptionBookingData,
  selected_flightInfo_data,
} from "../../../../slice/flightInfoSlice";
import ModalPopus from "../modals-popups";
import MobileFlightSummary from "../mobile-flight-summary";
import SelectFlightSummary from "../../select-flight-summary";
import TGIcon from "../../../../../../shared/tg-icon";

const RouteFlightListing: React.FC<any> = ({ flightDetails }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  let disableContinueBtn = useSelector(
    (state: RootState) => state?.osciReducer?.flightInfo?.diaplayContinueButton
  );

  const profileDetails = useSelector(
    (state: RootState) => state?.osciReducer?.profile.profileData
  );
  const { t, i18n } = useTranslation();
  const isDesktopView: boolean = useScreenSize().deviceSize.width > 767;
  const isMobile = useScreenSize()?.deviceSize?.width < 768;

  const [cardStickyClass, setCardStickyClass] = useState("");
  const [stickyClass, setStickyClass] = useState("");
  const [hideSelecedFlightDetails, setHideSelecedFlightDetails] =
    useState(false);
  const [hideContinueBtn, setHideContinueBtn] = useState(false);
  const [hideSelectedMulticity, setHideSelectedMulticity] = useState(Number);
  const [show, setShow] = useState(false);
  const [hideAndShowDepartReturn, setHideAndShowDepartReturn] = useState(false);

  const [displayMulticity, setDisplayMulticity] = useState(0);
  const [showFlightSummary, setShowFlightSummary] = useState(false);
  const [passSelectedFlightDetails, setPassSelectedFlightDetails] = useState(
    [] as any
  );
  const [showFinalSummary, setShowFinalSummary] = useState(true);

  useEffect(() => {
    window.addEventListener("scroll", stickNavbar);
  }, []);

  useEffect(() => {
    window.addEventListener("scroll", stickNavbarDate);
  }, []);

  useEffect(() => {
    if (isMobile) {
      setHideContinueBtn(true);
    }
  }, [flightDetails, isMobile]);

  const stickNavbar = () => {
    if (window !== undefined) {
      let windowHeight = window.scrollY;
      // window height changed for the scroll
      windowHeight > 150
        ? setCardStickyClass("stickynavcard")
        : setCardStickyClass("");
    }
  };

  const stickNavbarDate = () => {
    if (window !== undefined) {
      let windowHeight = window.scrollY;
      // window height changed for the scroll
      windowHeight > 150
        ? setStickyClass("dateStrickyView")
        : setStickyClass("");
    }
  };

  const showFlightSummaryFun = (data: boolean) => {
    if (data === true) {
      setShowFlightSummary(true);
    } else {
      selectedPreviousFlight(displayMulticity - 1);
      setShowFlightSummary(false);
    }
  };
  const selectedFlightsList: any = [];

  const setShowFinalSummaryProps = (data: any) => {
    if (data === true) {
      setShowFinalSummary(true);
    } else {
      setShowFinalSummary(false);
    }
  };
  const continueBtnMobileReturn = (index: number) => {
    setHideContinueBtn(true);
    setHideAndShowDepartReturn(true);
    selectedDepartAndArrivalTime(index);
    dispatch(disable_continue_btn(true));
  };
  const continueBtnMobileMulicity = (index: number) => {
    dispatch(disable_continue_btn(true));
    selectedDepartAndArrivalTime(index);
    setDisplayMulticity(index + 1);
    const selectedEle = document.getElementById("selectedInfoScroll");
    selectedEle?.scrollIntoView({ behavior: "smooth" });
    if (flightDetails.length - 1 === index) {
      // last index redirect to Flight Summary
      continueButton();
    }
  };

  const selectedDepartAndArrivalTime = (index: any) => {
    flightDetails?.map((res: any, index: number) => {
      const multiCityVal = JSON.parse(
        window.localStorage.getItem("flight" + index) as any
      );
      const returntripVal = JSON.parse(
        window.localStorage.getItem("departure") as any
      );
      const selectedFlightTime =
        res.journeyType === "Return" ? returntripVal : multiCityVal;

      selectedFlightTime &&
        selectedFlightTime.map((selectedValue: any, selectedIndex: number) => {
          for (let i = 0; i < flightDetails.length; i++) {
            let tempObj = {
              departureTime: "",
              arrivalTime: "",
            };
            if (i === index) {
              const flightDetailsArr = [...flightDetails];
              tempObj["departureTime"] =
                selectedValue.selectedFlight.departureTime;
              tempObj["arrivalTime"] = selectedValue.selectedFlight.arrivalTime;
              const finalSelectedObj = { ...flightDetails[i], ...tempObj };
              flightDetailsArr.splice(index, 1);
              flightDetailsArr.splice(index, 0, finalSelectedObj);
              flightDetails = [...flightDetailsArr];
              dispatch(saveRedemptionBookingData(flightDetails));
              break;
            }
          }
        });
    });
  };

  const selectedPreviousFlight = (index: number) => {
    let indexPlus = index === 0 ? index : index + 1;
    if (displayMulticity >= indexPlus) {
      if (index !== 0) {
        setDisplayMulticity(indexPlus - 1);
      } else {
        setDisplayMulticity(indexPlus);
      }
      setShowFlightSummary(false);
    }
  };

  const selectedPreviousFlightReturn = (index: number) => {
    if (index === 0) {
      setHideAndShowDepartReturn(false);
    }
  };

  const continueButton = () => {
    const totalMiles = profileDetails.remainingMiles;
    const totalMilesWithoutSpecialChar = parseFloat(
      totalMiles?.replace(/,/g, "")
    );

    // Passing the below list of selected flights to review passengers
    flightDetails?.map((data: any, index: number) => {
      if (data.journeyType === "Return") {
        if (data?.triptype === "departure") {
          const departureData = JSON.parse(
            window.localStorage.getItem("departure") as any
          );
          selectedFlightsList.push({
            departure: departureData,
          });
        } else if (data?.triptype === "arrival") {
          const arrivalData = JSON.parse(
            window.localStorage.getItem("arrival") as any
          );
          selectedFlightsList.push({
            arrival: arrivalData,
          });
        }
      } else if (data.journeyType === "Multi-City") {
        const selectedMultiCity = JSON.parse(
          window.localStorage.getItem("flight" + index) as any
        );
        selectedFlightsList.push({
          departure: selectedMultiCity,
        });
      } else if (data.journeyType === "One-way") {
        const selectedOneWayFlight = JSON.parse(
          window.localStorage.getItem("departure") as any
        );
        selectedFlightsList.push({
          departure: selectedOneWayFlight,
        });
      }
      if (selectedFlightsList?.length > 0) {
        if (selectedFlightsList[0].departure !== null) {
          if (
            data.journeyType === "One-way" ||
            data.journeyType === "Multi-City"
          ) {
            const newsRes = selectedFlightsList?.map(
              (values: any, index: any) => {
                if (values.departure !== null) {
                  return {
                    ...values.departure[0],
                  };
                }
              }
            );
            setPassSelectedFlightDetails(newsRes);
            dispatch(selected_flightInfo_data(newsRes));
          }
          if (data.journeyType === "Return") {
            const newsRes = selectedFlightsList?.map(
              (values: any, index: any) => {
                return {
                  ...values,
                };
              }
            );
            setPassSelectedFlightDetails(newsRes);
            dispatch(selected_flightInfo_data(newsRes));
          }
          // dispatch(selected_flightInfo_data(passSelectedFlightDetails))
        }
      }
      //   miles calculation starts here
      if (data.journeyType === "Return") {
        // mile calculation return trip starts here
        const departureData = JSON.parse(
          window.localStorage.getItem("departure") as any
        );
        const arrivalData = JSON.parse(
          window.localStorage.getItem("arrival") as any
        );
        let firstPosition = departureData;
        let secondPosition = arrivalData;
        let departureCount = 0;
        let arrivalCount = 0;
        for (let i = 0; i < firstPosition?.length; i++) {
          departureCount =
            departureCount + Number(firstPosition[i].bookingClass.miles);
        }
        for (let i = 0; i < secondPosition?.length; i++) {
          arrivalCount =
            arrivalCount + Number(secondPosition[i].bookingClass.miles);
        }
        let total_fare = departureCount + arrivalCount;
        if (total_fare !== 0) {
          window.localStorage.setItem(
            "totalSelectedMiles",
            JSON.stringify(total_fare)
          );
        }
        if (totalMilesWithoutSpecialChar < total_fare) {
          setShow(true);
        } else {
          if (data?.triptype === "arrival" && hideAndShowDepartReturn) {
            setShowFlightSummary(true);
          }
        }
        // mile calculation return trip ends here
      }
      if (data.journeyType === "One-way" || data.journeyType === "Multi-City") {
        let totalFare = 0;
        if (selectedFlightsList[0].departure !== null) {
          selectedFlightsList.map((item: any, index: any) => {
            item.departure.map((c: any, i: any) => {
              totalFare =
                c.bookingClass.miles !== undefined
                  ? totalFare + Number(c.bookingClass.miles)
                  : totalFare + 0;
            });
          });
        }
        if (totalFare !== 0) {
          window.localStorage.setItem(
            "totalSelectedMiles",
            JSON.stringify(totalFare)
          );
        }
        if (totalMilesWithoutSpecialChar < totalFare) {
          setShow(true);
        } else {
          setShowFlightSummary(true);
        }
      }
      //   miles calculation ends here
    });
    setTimeout(() => {
      flightDetails?.map((res: any, index: number) => {
        window.localStorage.removeItem("flight" + index);
      });
    }, 1000);
  };

  const hideFlightsParent = (data: any, selectedIndex: number) => {
    if (data) {
      setHideSelecedFlightDetails(true);
      setHideSelectedMulticity(selectedIndex);
    } else {
      setHideSelecedFlightDetails(false);
    }
  };

  // mobile part Continue button initially disabled when change flight from passenger page starts here
  const selectedContinueBtnFnc = (data: any) => {
    setHideAndShowDepartReturn(data);
  };

  const setShowFlightSummaryFunc = (data: any) => {
    setShowFlightSummary(data);
  };
  // mobile part Continue button initially disabled when change flight from passenger page ends here
  const setHideContinueBtnFnc = (data: any) => {
    if (isDesktopView) {
      if (data) {
        setHideContinueBtn(true);
      } else {
        setHideContinueBtn(false);
      }
    }
    if (isMobile) {
      if (data) {
        setHideContinueBtn(true);
      } else {
        setHideContinueBtn(false);
      }
    }
  };

  return (
    <>
      {!showFlightSummary && (
        <>
          {showFinalSummary && (
            <SelectFlightSummary flightDetails={flightDetails} />
          )}
          <div className={styles.routeFlightContainer}>
            {/* selected Info */}
            <>
              {/* the below selected info for Return and oneway trip view */}
              {hideAndShowDepartReturn && (
                <div
                  className={
                    !disableContinueBtn
                      ? `${styles.selectedInfoDivStricky} ${cardStickyClass}`
                      : styles.selectedInfoDiv
                  }
                >
                  {flightDetails?.map(
                    (selectedInfoData: any, index: number) => (
                      <div
                        className={
                          selectedInfoData?.triptype === "arrival"
                            ? styles.selectedInfoArrival
                            : styles.selectedInfo
                        }
                        onClick={() => selectedPreviousFlightReturn(index)}
                      >
                        {selectedInfoData?.triptype === "arrival" ? (
                          <div className={styles.selectedInfoEditIconDiv}>
                            <span className={styles.selectedInfoActive}>
                              {selectedInfoData?.from?.airportCode} -{" "}
                              {selectedInfoData?.to?.airportCode}
                            </span>
                            <div className={styles.editIconDiv}>
                              <TGIcon
                                icon="edit-icon-redeemption"
                                size={""}
                                fillColor={""}
                              />
                            </div>
                          </div>
                        ) : (
                          <span className={styles.selectedInfoFlights}>
                            {selectedInfoData?.from?.airportCode} -{" "}
                            {selectedInfoData?.to?.airportCode}
                          </span>
                        )}

                        <span className={styles.selectedInfoDateAndTime}>
                          {moment(selectedInfoData?.date).format("DD MMM YYYY")}
                        </span>
                        {selectedInfoData?.triptype !== "arrival" && (
                          <span className={styles.selectedInfoDateAndTime}>
                            {moment(
                              selectedInfoData?.departureTime,
                              "HHmm"
                            ).format("hh:mm")}
                            {" - "}
                            {moment(
                              selectedInfoData?.arrivalTime,
                              "HHmm"
                            ).format("hh:mm")}
                          </span>
                        )}
                      </div>
                    )
                  )}
                </div>
              )}
              {/*  */}
            </>
            <>
              {flightDetails?.map((data: any, index: number) => (
                <div className={styles.departReturnContainer}>
                  {/* multicity view */}
                  {data?.journeyType === "Multi-City" && (
                    <>
                      {index === 0 && displayMulticity === 0 ? (
                        <div>
                          <div
                            className={`global-content-margin ${styles.redemMilesContainer}`}
                          >
                            <div>
                              <span
                                className={styles.redeemMileDepartArrtLable}
                              >
                                {"Flight" +
                                  " " +
                                  (index + 1) +
                                  " of " +
                                  flightDetails.length}
                              </span>
                            </div>

                            <div className={styles.locationLabelCode}>
                              <span>
                                {data?.from?.cityName}
                                <span> ({data?.from?.airportCode})</span>
                              </span>
                              <span> - </span>
                              <span>
                                {data?.to?.cityName}
                                <span> ({data?.to?.airportCode})</span>
                              </span>
                            </div>
                          </div>
                          <div className={`${stickyClass}`}>
                            <DateSelectionWidget
                              iteration={index}
                              flightDetails={data}
                            />
                          </div>
                          <FlightCardMobile
                            iteration={index}
                            flightDetails={data}
                            hideFlightsParent={hideFlightsParent}
                            setHideContinueBtnFnc={setHideContinueBtnFnc}
                            selectedContinueBtnFnc={selectedContinueBtnFnc}
                            setShowFlightSummaryFunc={setShowFlightSummaryFunc}
                          />
                          {!disableContinueBtn && (
                            <div className={styles.continueBtnDiv}>
                              <button
                                className={styles.enabledContinueBtn}
                                onClick={() => continueBtnMobileMulicity(index)}
                              >
                                <span className={styles.continueBtnLabel}>
                                  {t("button_reset_continue")}
                                </span>
                              </button>
                            </div>
                          )}
                        </div>
                      ) : (
                        <>
                          {displayMulticity === index && (
                            <div>
                              <div
                                className={
                                  disableContinueBtn &&
                                  !flightDetails[flightDetails - 1]
                                    ? `${styles.selectedInfoDivStricky} ${cardStickyClass}`
                                    : styles.selectedInfoDiv
                                }
                              >
                                {flightDetails?.map(
                                  (selectedInfoData: any, index: number) => (
                                    <div
                                      className={
                                        displayMulticity === index
                                          ? styles.selectedInfoArrival
                                          : styles.selectedInfo
                                      }
                                      id="selectedInfoScroll"
                                      onClick={() =>
                                        selectedPreviousFlight(index)
                                      }
                                    >
                                      {displayMulticity === index ? (
                                        <div
                                          className={
                                            styles.selectedInfoEditIconDiv
                                          }
                                        >
                                          <span
                                            className={
                                              styles.selectedInfoActive
                                            }
                                          >
                                            {
                                              selectedInfoData?.from
                                                ?.airportCode
                                            }{" "}
                                            -{" "}
                                            {selectedInfoData?.to?.airportCode}
                                          </span>
                                          <div className={styles.editIconDiv}>
                                            <TGIcon
                                              icon="edit-icon-redeemption"
                                              size={""}
                                              fillColor={""}
                                            />
                                          </div>
                                        </div>
                                      ) : (
                                        <span
                                          className={styles.selectedInfoFlights}
                                        >
                                          {selectedInfoData?.from?.airportCode}{" "}
                                          - {selectedInfoData?.to?.airportCode}
                                        </span>
                                      )}
                                      <span
                                        className={
                                          styles.selectedInfoDateAndTime
                                        }
                                      >
                                        {moment(selectedInfoData?.date).format(
                                          "DD MMM YYYY"
                                        )}
                                      </span>
                                      {displayMulticity > index && (
                                        <span
                                          className={
                                            styles.selectedInfoDateAndTime
                                          }
                                        >
                                          {moment(
                                            selectedInfoData?.departureTime,
                                            "HHmm"
                                          ).format("hh:mm")}
                                          {" - "}
                                          {moment(
                                            selectedInfoData?.arrivalTime,
                                            "HHmm"
                                          ).format("hh:mm")}
                                        </span>
                                      )}
                                    </div>
                                  )
                                )}
                              </div>
                              <div
                                className={`global-content-margin ${styles.redemMilesContainer}`}
                              >
                                <div>
                                  <span
                                    className={styles.redeemMileDepartArrtLable}
                                  >
                                    {"Flight" +
                                      " " +
                                      (index + 1) +
                                      " of " +
                                      flightDetails.length}
                                  </span>
                                </div>

                                <div className={styles.locationLabelCode}>
                                  <span>
                                    {data?.from?.cityName}
                                    <span> ({data?.from?.airportCode})</span>
                                  </span>
                                  <span> - </span>
                                  <span>
                                    {data?.to?.cityName}
                                    <span> ({data?.to?.airportCode})</span>
                                  </span>
                                </div>
                              </div>
                              <div className={`${stickyClass}`}>
                                <DateSelectionWidget
                                  iteration={index}
                                  flightDetails={data}
                                />
                              </div>
                              <FlightCardMobile
                                iteration={index}
                                flightDetails={data}
                                hideFlightsParent={hideFlightsParent}
                                setHideContinueBtnFnc={setHideContinueBtnFnc}
                                selectedContinueBtnFnc={selectedContinueBtnFnc}
                                setShowFlightSummaryFunc={
                                  setShowFlightSummaryFunc
                                }
                              />
                              {!disableContinueBtn && (
                                <div className={styles.continueBtnDiv}>
                                  <button
                                    className={styles.enabledContinueBtn}
                                    onClick={() =>
                                      continueBtnMobileMulicity(index)
                                    }
                                  >
                                    <span className={styles.continueBtnLabel}>
                                      {t("button_reset_continue")}
                                    </span>
                                  </button>
                                </div>
                              )}
                            </div>
                          )}
                        </>
                      )}
                    </>
                  )}

                  {/* one way View */}
                  {data?.journeyType === "One-way" && (
                    <>
                      <div
                        className={`global-content-margin ${styles.redemMilesContainer}`}
                      >
                        <div>
                          <span className={styles.redeemMileDepartArrtLable}>
                            {data?.triptype === "departure"
                              ? "Depart"
                              : data?.triptype}
                          </span>
                        </div>

                        <div className={styles.locationLabelCode}>
                          <span>
                            {data?.from?.cityName}
                            <span> ({data?.from?.airportCode})</span>
                          </span>
                          <span> - </span>
                          <span>
                            {data?.to?.cityName}
                            <span> ({data?.to?.airportCode})</span>
                          </span>
                        </div>
                      </div>
                      <div className={`${stickyClass}`}>
                        <DateSelectionWidget
                          iteration={index}
                          flightDetails={data}
                        />
                      </div>
                      <FlightCardMobile
                        iteration={index}
                        flightDetails={data}
                        hideFlightsParent={hideFlightsParent}
                        setHideContinueBtnFnc={setHideContinueBtnFnc}
                        selectedContinueBtnFnc={selectedContinueBtnFnc}
                        setShowFlightSummaryFunc={setShowFlightSummaryFunc}
                      />
                    </>
                  )}
                  {/* Return trip view */}
                  {/* // new figma changes Return and onw-way type */}
                  {!hideAndShowDepartReturn &&
                    data?.triptype === "departure" &&
                    data?.journeyType === "Return" && (
                      <>
                        <div
                          className={`global-content-margin ${styles.redemMilesContainer}`}
                        >
                          <div>
                            <span className={styles.redeemMileDepartArrtLable}>
                              {data?.triptype === "departure"
                                ? "Depart"
                                : "Return"}
                            </span>
                          </div>

                          <div className={styles.locationLabelCode}>
                            <span>
                              {data?.from?.cityName}
                              <span> ({data?.from?.airportCode})</span>
                            </span>
                            <span> - </span>
                            <span>
                              {data?.to?.cityName}
                              <span> ({data?.to?.airportCode})</span>
                            </span>
                          </div>
                        </div>
                        <div className={`${stickyClass}`}>
                          <DateSelectionWidget
                            iteration={index}
                            flightDetails={data}
                          />
                        </div>
                        <FlightCardMobile
                          iteration={index}
                          flightDetails={data}
                          hideFlightsParent={hideFlightsParent}
                          setHideContinueBtnFnc={setHideContinueBtnFnc}
                          selectedContinueBtnFnc={selectedContinueBtnFnc}
                          setShowFlightSummaryFunc={setShowFlightSummaryFunc}
                        />
                        <div>
                          {flightDetails[index]?.data?.flightList?.length >
                            0 && (
                            <span className={styles.noOfMiles}>
                              {t(
                                "label_redemption_message_number_of_miles_for_one_passenger"
                              )}
                            </span>
                          )}
                        </div>
                        {!disableContinueBtn && (
                          <div className={styles.continueBtnDiv}>
                            <button
                              className={styles.enabledContinueBtn}
                              onClick={() => continueBtnMobileReturn(index)}
                            >
                              <span className={styles.continueBtnLabel}>
                                {t("button_reset_continue")}
                              </span>
                            </button>
                          </div>
                        )}
                      </>
                    )}

                  {hideAndShowDepartReturn &&
                    data?.triptype === "arrival" &&
                    data?.journeyType === "Return" && (
                      <>
                        <div
                          className={`global-content-margin ${styles.redemMilesContainer}`}
                        >
                          <div>
                            <span className={styles.redeemMileDepartArrtLable}>
                              {data?.triptype === "departure"
                                ? "Depart"
                                : "Return"}
                            </span>
                          </div>

                          <div className={styles.locationLabelCode}>
                            <span>
                              {data?.from?.cityName}
                              <span> ({data?.from?.airportCode})</span>
                            </span>
                            <span> - </span>
                            <span>
                              {data?.to?.cityName}
                              <span> ({data?.to?.airportCode})</span>
                            </span>
                          </div>
                        </div>
                        <div className={`${stickyClass}`}>
                          <DateSelectionWidget
                            iteration={index}
                            flightDetails={data}
                          />
                        </div>
                        <FlightCardMobile
                          iteration={index}
                          flightDetails={data}
                          hideFlightsParent={hideFlightsParent}
                          setHideContinueBtnFnc={setHideContinueBtnFnc}
                          selectedContinueBtnFnc={selectedContinueBtnFnc}
                          setShowFlightSummaryFunc={setShowFlightSummaryFunc}
                        />
                        <div>
                          <span className={styles.noOfMiles}>
                            {t(
                              "label_redemption_message_number_of_miles_for_one_passenger"
                            )}
                          </span>
                        </div>
                      </>
                    )}
                </div>
              ))}
            </>
            {hideContinueBtn && !disableContinueBtn && (
              <div className={styles.continueBtnDiv}>
                <button
                  className={
                    disableContinueBtn
                      ? styles.disbaledContinueBtn
                      : styles.enabledContinueBtn
                  }
                  disabled={disableContinueBtn}
                  onClick={() => continueButton()}
                >
                  <span className={styles.continueBtnLabel}>
                    {t("button_reset_continue")}
                  </span>
                </button>
              </div>
            )}
            {/* total miles exceeded popup */}
            {show && <ModalPopus />}
          </div>
        </>
      )}

      {showFlightSummary && (
        <MobileFlightSummary
          showFlights={showFlightSummaryFun}
          selectedFlightsListData={selectedFlightsList}
          getSelectedFlight={passSelectedFlightDetails}
          flightDetails={flightDetails}
          hideFlightSummary={setShowFinalSummaryProps}
        />
      )}
    </>
  );
};

export default RouteFlightListing;
