import React, { useEffect, useState } from "react";
import styles from "./mobile-flight-summary.module.scss";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import TGIcon from "../../../../../../shared/tg-icon";
import moment from "moment";
import { useNavigate } from "react-router";
import FlightDetails from "../../flight-card/flight-card-mobile/flight-details-model";
import { RootState } from "../../../../slice/RootReducer";
import { Root } from "react-dom/client";

interface Props {
  selectedFlightsListData: any;
  showFlights: any;
  getSelectedFlight: any;
  flightDetails: any;
  hideFlightSummary: any;
}

const MobileFlightSummary: React.FC<Props> = (props: Props) => {
  const { t, i18n } = useTranslation();
  const navigate = useNavigate();
  const profileDetails = useSelector(
    (state: RootState) => state?.osciReducer?.profile.profileData
  );
  const [newResponse, setNewResponse] = useState(Array<[]>);
  const [showReturnTrip, setShowReturnTrip] = useState("OneWayAndMuliticity");
  const [showFlightDetailsPopup, setShowDetailsFlightPopup] = useState(false);
  const [flightDetailList, setFlightDetailList] = useState({});
  const [getTotalSelectedMiles, setGetTotalSelectedMiles] = useState<
    any | null
  >(null);

  const selectedFlightData = useSelector(
    (state: RootState) => state?.osciReducer?.flightInfo
  );

  useEffect(() => {
    const miles = window.localStorage.getItem("totalSelectedMiles");
    setGetTotalSelectedMiles(miles);
  }, []);

  useEffect(() => {
    props.flightDetails?.map((res: any, index: number) => {
      if (res.journeyType === "Return") {
        setShowReturnTrip("Return");
      }
    });
  }, [props.flightDetails]);

  // mobile part Continue button initially disabled when change flight from passenger page
  useEffect(() => {
    setTimeout(() => {
      if (selectedFlightData?.selectedFlightList?.length > 0) {
        setNewResponse(selectedFlightData?.selectedFlightList);
      }
    });
  }, [selectedFlightData?.selectedFlightList]);

  const changeFlight = () => {
    props.showFlights(false); // show flights
    props.hideFlightSummary(true); // show edit search
  };

  const flightSummaryContinueBtn = () => {
    window.localStorage.removeItem("departure");
    window.localStorage.removeItem("arrival");
    navigate(`/${i18n?.language}/redemption/passengers`);
  };

  const showFlightDetailsFunc = (
    event: React.MouseEvent<HTMLDivElement, MouseEvent>,
    responseData: any
  ) => {
    setShowDetailsFlightPopup(true);
    const obj = {
      responseData,
    };
    setFlightDetailList(obj);
    event.stopPropagation();
  };

  const handleClose = () => {
    setShowDetailsFlightPopup(false);
  };

  return (
    <>
      <div className={styles.flightSummaryDiv}>
        <div className={styles.flightSummary} onClick={() => changeFlight()}>
          <TGIcon icon="redemption-summary-left-arrow" fillColor="" size="" />
          <span className={styles.flightSummaryLabel}>
            {t("label_redemption_flight_summary")}
          </span>
        </div>
        {showReturnTrip === "OneWayAndMuliticity" && (
          <>
            {newResponse?.map((resp: any, index: any) => (
              <div className={styles.summaryCard}>
                <div key={index}>
                  <div className={styles.triptypeAndClass}>
                    {resp?.airportLocations?.journeyType === "Multi-City" ? (
                      <span className={styles.tripTypeVal}>
                        {"Flight" + " " + (index + 1)}
                      </span>
                    ) : (
                      <span className={styles.tripTypeVal}>
                        {resp?.airportLocations?.triptype === "departure"
                          ? "Depart"
                          : "Return"}
                      </span>
                    )}
                    <span className={styles.classlistVal}>
                      {resp?.bookingClass?.classDesc}
                    </span>
                  </div>
                  <div className={styles["mobile-flight-summary-card-section"]}>
                    <div className={styles["mobile-flight-section"]}>
                      <div
                        className={styles["mobile-flight-summary-date-section"]}
                      >
                        <div>
                          {moment(
                            resp?.selectedFlight?.departureDate,
                            "DDMMYY"
                          ).format("ddd, DD MMM YYYY")}
                        </div>
                        <div>
                          {moment(
                            resp?.selectedFlight?.arrivalDate,
                            "DDMMYY"
                          ).format("ddd, DD MMM YYYY")}
                        </div>
                      </div>
                      <div
                        className={
                          styles["mobile-flight-summary-travel-time-details"]
                        }
                      >
                        <div className={styles["mob-flight-departure-details"]}>
                          <div>
                            {moment(
                              resp?.selectedFlight?.departureTime,
                              "HHmm"
                            ).format("hh:mm")}
                          </div>
                          <div>{resp?.selectedFlight?.departure}</div>
                        </div>
                        <div className={styles["mob-flight-duration-details"]}>
                          <div>
                            {moment
                              .utc(
                                moment(
                                  resp?.selectedFlight?.arrivalTime,
                                  "HHmm"
                                ).diff(
                                  moment(
                                    resp?.selectedFlight?.departureTime,
                                    "HHmm"
                                  )
                                )
                              )
                              .format("h [h] m [min]")}
                          </div>
                          <div>
                            <div></div>
                            <div>
                              <TGIcon
                                icon="plane-icon-redemption"
                                fillColor=""
                                size=""
                              />
                            </div>
                            <div></div>
                          </div>
                          <div>{t("label_nonstop")}</div>
                        </div>
                        <div className={styles["mob-flight-arrival-details"]}>
                          <div>
                            {moment(
                              resp?.selectedFlight?.arrivalTime,
                              "HHmm"
                            ).format("hh:mm")}
                          </div>
                          <div>{resp?.selectedFlight?.arrival}</div>
                        </div>
                      </div>
                      <div
                        className={styles["mob-flight-summary-airport-details"]}
                      >
                        <div>
                          <div>{resp?.airportLocations?.from?.airportName}</div>
                          <div>
                            <span>
                              {t("label_redemption_termainal")}{" "}
                              {resp?.airportLocations?.to?.departureTerminal}
                            </span>
                          </div>
                        </div>
                        <div>
                          <div>{resp?.airportLocations?.to?.airportName}</div>
                          <div>
                            <span>
                              {t("label_redemption_termainal")}{" "}
                              {resp?.airportLocations?.to?.arrivalTerminal}
                            </span>
                          </div>
                        </div>
                      </div>
                      <div className={styles["mob-flight-summary-mileage"]}>
                        {resp?.bookingClass?.classDesc}
                      </div>
                    </div>
                    <div className={styles["mobile-flight-button-section"]}>
                      <div className={styles["mobile-flight-action-section"]}>
                        <div
                          className={styles["mobile-flight-change"]}
                          onClick={() => changeFlight()}
                        >
                          {t("labe_change_flight")}
                        </div>

                        <div
                          className={styles["mobile-flight-details"]}
                          onClick={(e) => showFlightDetailsFunc(e, resp)}
                        >
                          {t("label_show_details")}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            ))}
          </>
        )}

        {showReturnTrip === "Return" && (
          <>
            {newResponse?.map((response: any, index: any) => (
              <div key={index} className={styles.summaryCard}>
                <>
                  {/* show departure */}
                  {response?.departure?.map((resp: any, index: any) => (
                    <div key={index}>
                      <div className={styles.triptypeAndClass}>
                        <span className={styles.tripTypeVal}>
                          {resp?.airportLocations?.triptype === "departure"
                            ? "Depart"
                            : "Return"}
                        </span>
                        <span className={styles.classlistVal}>
                          {resp?.bookingClass?.classDesc}
                        </span>
                      </div>
                      <div
                        className={styles["mobile-flight-summary-card-section"]}
                      >
                        <div className={styles["mobile-flight-section"]}>
                          <div
                            className={
                              styles["mobile-flight-summary-date-section"]
                            }
                          >
                            <div>
                              {moment(
                                resp?.selectedFlight?.departureDate,
                                "DDMMYY"
                              ).format("ddd, DD MMM YYYY")}
                            </div>
                            <div>
                              {moment(
                                resp?.selectedFlight?.arrivalDate,
                                "DDMMYY"
                              ).format("ddd, DD MMM YYYY")}
                            </div>
                          </div>
                          <div
                            className={
                              styles[
                                "mobile-flight-summary-travel-time-details"
                              ]
                            }
                          >
                            <div
                              className={styles["mob-flight-departure-details"]}
                            >
                              <div>
                                {moment(
                                  resp?.selectedFlight?.departureTime,
                                  "HHmm"
                                ).format("hh:mm")}
                              </div>
                              <div>{resp?.selectedFlight?.departure}</div>
                            </div>
                            <div
                              className={styles["mob-flight-duration-details"]}
                            >
                              <div>
                                {moment
                                  .utc(
                                    moment(
                                      resp?.selectedFlight?.arrivalTime,
                                      "HHmm"
                                    ).diff(
                                      moment(
                                        resp?.selectedFlight?.departureTime,
                                        "HHmm"
                                      )
                                    )
                                  )
                                  .format("h [h] m [min]")}
                              </div>
                              <div>
                                <div></div>
                                <div>
                                  <TGIcon
                                    icon="plane-icon-redemption"
                                    fillColor=""
                                    size=""
                                  />
                                </div>
                                <div></div>
                              </div>
                              <div>{t("label_nonstop")}</div>
                            </div>
                            <div
                              className={styles["mob-flight-arrival-details"]}
                            >
                              <div>
                                {moment(
                                  resp?.selectedFlight?.arrivalTime,
                                  "HHmm"
                                ).format("hh:mm")}
                              </div>
                              <div>{resp?.selectedFlight?.arrival}</div>
                            </div>
                          </div>
                          <div
                            className={
                              styles["mob-flight-summary-airport-details"]
                            }
                          >
                            <div>
                              <div>
                                {resp?.airportLocations?.from?.airportName}
                              </div>
                              <div>
                                <span>
                                  {t("label_redemption_termainal")}{" "}
                                  {
                                    resp?.airportLocations?.to
                                      ?.departureTerminal
                                  }
                                </span>
                              </div>
                            </div>
                            <div>
                              <div>
                                {resp?.airportLocations?.to?.airportName}
                              </div>
                              <div>
                                <span>
                                  {t("label_redemption_termainal")}{" "}
                                  {resp?.airportLocations?.to?.arrivalTerminal}
                                </span>
                              </div>
                            </div>
                          </div>
                          <div className={styles["mob-flight-summary-mileage"]}>
                            {resp?.bookingClass?.classDesc}
                          </div>
                        </div>
                        <div className={styles["mobile-flight-button-section"]}>
                          <div
                            className={styles["mobile-flight-action-section"]}
                          >
                            <div
                              className={styles["mobile-flight-change"]}
                              onClick={() => changeFlight()}
                            >
                              {t("labe_change_flight")}
                            </div>

                            <div
                              className={styles["mobile-flight-details"]}
                              onClick={(e) => showFlightDetailsFunc(e, resp)}
                            >
                              {t("label_show_details")}
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  ))}
                </>
                <>
                  {/* show arrival */}
                  {response?.arrival?.map((resp: any, index: any) => (
                    <div key={index}>
                      <div className={styles.triptypeAndClass}>
                        <span className={styles.tripTypeVal}>
                          {resp?.airportLocations?.triptype === "departure"
                            ? "Depart"
                            : "Return"}
                        </span>
                        <span className={styles.classlistVal}>
                          {resp?.bookingClass?.classDesc}
                        </span>
                      </div>
                      <div
                        className={styles["mobile-flight-summary-card-section"]}
                      >
                        <div className={styles["mobile-flight-section"]}>
                          <div
                            className={
                              styles["mobile-flight-summary-date-section"]
                            }
                          >
                            <div>
                              {moment(
                                resp?.selectedFlight?.departureDate,
                                "DDMMYY"
                              ).format("ddd, DD MMM YYYY")}
                            </div>
                            <div>
                              {moment(
                                resp?.selectedFlight?.arrivalDate,
                                "DDMMYY"
                              ).format("ddd, DD MMM YYYY")}
                            </div>
                          </div>
                          <div
                            className={
                              styles[
                                "mobile-flight-summary-travel-time-details"
                              ]
                            }
                          >
                            <div
                              className={styles["mob-flight-departure-details"]}
                            >
                              <div>
                                {moment(
                                  resp?.selectedFlight?.departureTime,
                                  "HHmm"
                                ).format("hh:mm")}
                              </div>
                              <div>{resp?.selectedFlight?.departure}</div>
                            </div>
                            <div
                              className={styles["mob-flight-duration-details"]}
                            >
                              <div>
                                {moment
                                  .utc(
                                    moment(
                                      resp?.selectedFlight?.arrivalTime,
                                      "HHmm"
                                    ).diff(
                                      moment(
                                        resp?.selectedFlight?.departureTime,
                                        "HHmm"
                                      )
                                    )
                                  )
                                  .format("h [h] m [min]")}
                              </div>
                              <div>
                                <div></div>
                                <div>
                                  <TGIcon
                                    icon="plane-icon-redemption"
                                    fillColor=""
                                    size=""
                                  />
                                </div>
                                <div></div>
                              </div>
                              <div>{t("label_nonstop")}</div>
                            </div>
                            <div
                              className={styles["mob-flight-arrival-details"]}
                            >
                              <div>
                                {moment(
                                  resp?.selectedFlight?.arrivalTime,
                                  "HHmm"
                                ).format("hh:mm")}
                              </div>
                              <div>{resp?.selectedFlight?.arrival}</div>
                            </div>
                          </div>
                          <div
                            className={
                              styles["mob-flight-summary-airport-details"]
                            }
                          >
                            <div>
                              <div>
                                {resp?.airportLocations?.from?.airportName}
                              </div>
                              <div>
                                <span>
                                  {t("label_redemption_termainal")}{" "}
                                  {
                                    resp?.airportLocations?.to
                                      ?.departureTerminal
                                  }
                                </span>
                              </div>
                            </div>
                            <div>
                              <div>
                                {resp?.airportLocations?.to?.airportName}
                              </div>
                              <div>
                                <span>
                                  {t("label_redemption_termainal")}{" "}
                                  {resp?.airportLocations?.to?.arrivalTerminal}
                                </span>
                              </div>
                            </div>
                          </div>
                          <div className={styles["mob-flight-summary-mileage"]}>
                            {resp?.bookingClass?.classDesc}
                          </div>
                        </div>
                        <div className={styles["mobile-flight-button-section"]}>
                          <div
                            className={styles["mobile-flight-action-section"]}
                          >
                            <div
                              className={styles["mobile-flight-change"]}
                              onClick={() => changeFlight()}
                            >
                              {t("labe_change_flight")}
                            </div>

                            <div
                              className={styles["mobile-flight-details"]}
                              onClick={(e) => showFlightDetailsFunc(e, resp)}
                            >
                              {t("label_show_details")}
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  ))}
                </>
              </div>
            ))}
          </>
        )}

        <div className={styles.continueBtnDiv}>
          <div className={styles.totalMilesDiv}>
            <div className={styles.totalmileAndPassengers}>
              <span className={styles.totalMilesLabel}>
                {t("label_redemption_total_miles_for")}{" "}
                {props.flightDetails[0]?.pax} {t("label_passenger")}
              </span>
              <span className={styles.surchargeAndTaxesLabel}>
                {t("label_redemption_surchase_and_taxes")}
              </span>
            </div>
            <span className={styles.displayTotalMiles}>
              {getTotalSelectedMiles} {t("label_redemption_header_miles")}
            </span>
          </div>
          <button
            className={styles.enabledContinueBtn}
            onClick={() => flightSummaryContinueBtn()}
          >
            <span className={styles.continueBtnLabel}>
              {t("button_reset_continue")}
            </span>
          </button>
        </div>

        {showFlightDetailsPopup && (
          <FlightDetails
            hidePanel={handleClose}
            flightDetailsSummary={flightDetailList}
          />
        )}
      </div>
    </>
  );
};

export default MobileFlightSummary;
