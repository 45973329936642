import React, { useEffect, useState } from "react";
import TGIcon from "../../../../../shared/tg-icon";
import { Button } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import useScreenSize from "../../../../../utils/hook/useScreenSize";
import styles from "./select-flight-summary.module.scss";
import moment from "moment";
import RedemptionBookingWidget from "../../../component/redemption-booking-widget";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../../slice/RootReducer";
import { useNavigate } from "react-router-dom";
import { resetPersistStore } from "../../../../../store";
import { clearSelectedFlightIndex } from "../../../slice/flightInfoSlice";

const SelectFlightSummary: React.FC<any> = ({ flightDetails }) => {
  const { t, i18n } = useTranslation();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const isDesktopView: boolean = useScreenSize().deviceSize.width > 767;
  const FlightFare = useSelector(
    (state: RootState) => state?.osciReducer?.flightFare?.data
  );
  const profileDetails = useSelector(
    (state: RootState) => state?.osciReducer?.profile.profileData
  );
  const totalMiles = profileDetails.remainingMiles;
  const totalMilesWithoutSpecialChar = parseFloat(
    totalMiles?.replace(/,/g, "")
  );
  const [isEdit, setIsEdit] = useState(false);

  const [stickyClass, setStickyClass] = useState("");
  const [isOffCanvas, setIsOffCanvas] = useState<boolean>(false);

  useEffect(() => {
    window.addEventListener("scroll", stickNavbar);
    return () => window.removeEventListener("scroll", stickNavbar);
  }, []);

  useEffect(() => {
    /// dynamically hiding the miles container
    if (isEdit) {
      const elem = document?.getElementById("redeemButtonContainer");
      if (elem) {
        elem.style.visibility = "hidden";
      }
    }
  }, [isEdit]);

  const stickNavbar = () => {
    if (window !== undefined) {
      let windowHeight = window.scrollY;
      // window height changed for the scroll
      windowHeight > 150 ? setStickyClass("stickynav") : setStickyClass("");
    }
  };

  const editSearch = () => {
    resetPersistStore();
    dispatch(clearSelectedFlightIndex());
    setIsEdit(!isEdit);
    if (!isDesktopView) {
      setIsOffCanvas(true);
    }
  };

  const handleOffCanvas = () => {
    setIsOffCanvas(false);
    setIsEdit(false);
  };

  const purchaseMiles = () => {
    navigate(`/${i18n?.language}`);
  };

  const showEditSummary = (
    data: boolean | ((prevState: boolean) => boolean)
  ) => {
    setIsEdit(data);
  };

  return (
    <>
      <>
        {!isEdit && (
          <div>
            {totalMilesWithoutSpecialChar < 0 ? (
              <div className={styles.noMileContainer}>
                <TGIcon
                  icon="redemption-noticifation-icon"
                  size={""}
                  fillColor={""}
                />
                <div className={styles.paragraphDiv}>
                  <span className={styles.paragraphTextHeader}>
                    {t("label_dont_have_enought_miles_text")}
                  </span>
                  <p className={styles.paragraphText}>
                    {t("label_purchase_more_miles")}
                  </p>
                  <div className={styles.purchasemiles}>
                    <button
                      className={styles.purchaseMilesBtn}
                      onClick={() => purchaseMiles()}
                    >
                      <div className={styles.purchasemilesLabelDiv}>
                        <span className={styles.purchasemilesLabel}>
                          {t("button_label_purchase_miles")}
                        </span>
                        <TGIcon
                          icon="redemption-right-arrow"
                          size={""}
                          fillColor={""}
                        />
                      </div>
                    </button>
                  </div>
                </div>
              </div>
            ) : (
              <div
                className={
                  isDesktopView
                    ? `${styles.symmaryContainer} ${stickyClass}`
                    : `${styles.symmaryContainer}`
                }
              >
                <div className={styles.summaryDiv}>
                  <div className={styles.summaryWithMiles}>
                    <div className={styles.summaryWithPassengers}>
                      <>
                        {flightDetails[0].journeyType === "Multi-City" ? (
                          <div className={styles.orginDestnDiv}>
                            <span className={styles.originDestnVal}>
                              {flightDetails.map(
                                (details: any, index: number) =>
                                  flightDetails[0].journeyType === "Multi-City"
                                    ? index === 0
                                      ? `${details?.from?.airportCode}-${details?.to?.airportCode}`
                                      : ` // ${details?.from?.airportCode}-${details?.to?.airportCode}`
                                    : null
                              )}
                            </span>
                          </div>
                        ) : (
                          <div className={styles.orginDestnDiv}>
                            <span className={styles.originDestnVal}>
                              {flightDetails[0]?.from?.airportCode}
                            </span>
                            <div className={styles.doubleArrow}>
                              {flightDetails[0].journeyType === "One-way" ? (
                                <TGIcon
                                  icon="redemption-oneway-flightIcon"
                                  size={""}
                                  fillColor={""}
                                />
                              ) : (
                                <TGIcon
                                  icon="redemption-double-arrow"
                                  size={""}
                                  fillColor={""}
                                />
                              )}
                            </div>
                            <span className={styles.originDestnVal}>
                              {flightDetails[0]?.to?.airportCode}
                            </span>
                          </div>
                        )}
                        {flightDetails[0].journeyType === "One-way" ? (
                          <span className={styles.orginDestnDate}>
                            {moment(flightDetails[0]?.date, "DDMMYY").format(
                              "DD MMM YYYY"
                            )}{" "}
                          </span>
                        ) : (
                          <span className={styles.orginDestnDate}>
                            {moment(flightDetails[0]?.date, "DDMMYY").format(
                              "DD MMM YYYY"
                            )}{" "}
                            -{" "}
                            {moment(
                              flightDetails[flightDetails.length - 1].date,
                              "DDMMYY"
                            ).format("DD MMM YYYY")}
                          </span>
                        )}
                      </>
                      <span className={styles.passengersVal}>
                        {flightDetails[0]?.pax}{" "}
                        {flightDetails[0]?.pax === 1
                          ? t("label_passenger")
                          : t("label_book_widget_passengers")}
                      </span>
                    </div>

                    {isDesktopView && (
                      <>
                        {Object.keys(FlightFare).length > 0 && (
                          <div className={styles.borderLine}>
                            <span className={styles.displayTotalMiles}>
                              {FlightFare &&
                                FlightFare?.data?.flightsFare[0].miles}{" "}
                            </span>
                            <span>{t("label_redemption_header_miles")}</span>
                          </div>
                        )}
                      </>
                    )}
                  </div>
                  <div className={styles.buttonCol}>
                    <Button
                      className={styles.editSearchButton}
                      onClick={editSearch}
                      aria-label="Search Flights Button"
                    >
                      <span className={styles.editSearchLable}>
                        {t("label_redemption_edit_search")}
                      </span>
                    </Button>
                  </div>
                </div>
              </div>
            )}
          </div>
        )}
      </>
      <>
        {isEdit && (
          <div
            className={styles.editWidget}
            id="redemption-select-flight-edit-widget"
          >
            <div className={styles.closeIcon} onClick={editSearch}>
              <TGIcon
                size={"24px"}
                fillColor={"#181B47"}
                icon={"close-icon"}
              ></TGIcon>
            </div>
            <RedemptionBookingWidget
              redeemState={false}
              // isDesktopView={true}
              handleRedeemInactive={() => {}}
              isOffCanvas={!isDesktopView && isOffCanvas ? true : false}
              onHideCanvas={!isDesktopView ? handleOffCanvas : () => {}}
              showEditSummary={showEditSummary}
            />
          </div>
        )}
      </>
    </>
  );
};

export default SelectFlightSummary;
