import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import useScreenSize from "../../../../../utils/hook/useScreenSize";
import styles from "./route-flight-listing.module.scss";
import FlightCard from "../flight-card";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../../slice/RootReducer";
import DateSelectionWidget from "../date-selection-widget";
import { useNavigate } from "react-router";
import ModalPopus from "./modals-popups";
import { selected_flightInfo_data } from "../../../slice/flightInfoSlice";

const RouteFlightListing: React.FC<any> = ({ flightDetails }) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  let disableContinueBtn = useSelector(
    (state: RootState) => state?.osciReducer?.flightInfo?.diaplayContinueButton
  );

  useEffect(() => {
    if (!disableContinueBtn) {
      setHideContinueBtn(true);
    }
  }, [disableContinueBtn]);

  const profileDetails = useSelector(
    (state: RootState) => state?.osciReducer?.profile.profileData
  );
  const { t, i18n } = useTranslation();
  const isDesktopView: boolean = useScreenSize().deviceSize.width > 767;
  const [hideSelecedFlightDetails, setHideSelecedFlightDetails] =
    useState(false);

  const [hideContinueBtn, setHideContinueBtn] = useState(false);
  const [hideSelectedMulticity, setHideSelectedMulticity] = useState(Number);
  const [show, setShow] = useState(false);
  const [passSelectedFlightDetails, setPassSelectedFlightDetails] = useState(
    [] as any
  );

  const selectedFlightsList: { departure?: any; arrival?: any }[] = [];
  const continueButton = () => {
    const totalMiles = profileDetails.remainingMiles;
    const totalMilesWithoutSpecialChar = parseFloat(
      totalMiles?.replace(/,/g, "")
    );

    // Passing the below list of selected flights to review passengers
    flightDetails?.map((data: any, index: number) => {
      if (data.journeyType === "Return") {
        if (data?.triptype === "departure") {
          const departureData = JSON.parse(
            window.localStorage.getItem("departure") as any
          );
          selectedFlightsList.push({
            departure: departureData,
          });
        } else if (data?.triptype === "arrival") {
          const arrivalData = JSON.parse(
            window.localStorage.getItem("arrival") as any
          );
          selectedFlightsList.push({
            arrival: arrivalData,
          });
        }
      } else if (data.journeyType === "Multi-City") {
        const selectedMultiCity = JSON.parse(
          window.localStorage.getItem("flight" + index) as any
        );
        selectedFlightsList.push({
          departure: selectedMultiCity,
        });
      } else if (data.journeyType === "One-way") {
        const selectedOneWayFlight = JSON.parse(
          window.localStorage.getItem("departure") as any
        );
        selectedFlightsList.push({
          departure: selectedOneWayFlight,
        });
      }
      if (selectedFlightsList?.length > 0) {
        if (selectedFlightsList[0].departure !== null) {
          if (
            data.journeyType === "One-way" ||
            data.journeyType === "Multi-City"
          ) {
            const newsRes = selectedFlightsList?.map(
              (values: any, index: any) => {
                if (values.departure !== null) {
                  return {
                    ...values.departure[0],
                  };
                }
              }
            );
            setPassSelectedFlightDetails(newsRes);
            // dispatch(selected_flightInfo_data([{ departure: newsRes }]));
            dispatch(selected_flightInfo_data(newsRes));
          }
          if (data.journeyType === "Return") {
            const newsRes = selectedFlightsList?.map(
              (values: any, index: any) => {
                return {
                  ...values,
                };
              }
            );
            setPassSelectedFlightDetails(newsRes);
            dispatch(selected_flightInfo_data(newsRes));
          }
        }
      }
      //   miles calculation starts here
      if (data.journeyType === "Return") {
        // mile calculation return trip starts here
        const departureData = JSON.parse(
          window.localStorage.getItem("departure") as any
        );
        const arrivalData = JSON.parse(
          window.localStorage.getItem("arrival") as any
        );
        let firstPosition = departureData;
        let secondPosition = arrivalData;
        let departureCount = 0;
        let arrivalCount = 0;
        for (let i = 0; i < firstPosition?.length; i++) {
          departureCount =
            departureCount + Number(firstPosition[i].bookingClass.miles);
        }
        for (let i = 0; i < secondPosition?.length; i++) {
          arrivalCount =
            arrivalCount + Number(secondPosition[i].bookingClass.miles);
        }
        let total_fare = departureCount + arrivalCount;
        if (total_fare !== 0) {
          window.localStorage.setItem(
            "totalSelectedMiles",
            JSON.stringify(total_fare)
          );
        }
        if (totalMilesWithoutSpecialChar < total_fare) {
          setShow(true);
        } else {
          if (data?.triptype === "arrival") {
            navigate(`/${i18n?.language}/redemption/passengers`);
          }
        }
        // mile calculation return trip ends here
      }
      if (data.journeyType === "One-way" || data.journeyType === "Multi-City") {
        let totalFare = 0;
        if (selectedFlightsList[0].departure !== null) {
          selectedFlightsList.map((item: any, index: any) => {
            item.departure.map((c: any, i: any) => {
              totalFare =
                c.bookingClass.miles !== undefined
                  ? totalFare + Number(c.bookingClass.miles)
                  : totalFare + 0;
            });
          });
        }
        if (totalFare !== 0) {
          window.localStorage.setItem(
            "totalSelectedMiles",
            JSON.stringify(totalFare)
          );
        }
        if (totalMilesWithoutSpecialChar < totalFare) {
          setShow(true);
        } else {
          navigate(`/${i18n?.language}/redemption/passengers`);
        }
      }
      //   miles calculation ends here
    });
    setTimeout(() => {
      window.localStorage.removeItem("departure");
      window.localStorage.removeItem("arrival");
      flightDetails?.map((res: any, index: number) => {
        window.localStorage.removeItem("flight" + index);
      });
    }, 1000);
  };

  const hideFlightsParent = (data: any, selectedIndex: number) => {
    if (data) {
      setHideSelecedFlightDetails(true);
      setHideSelectedMulticity(selectedIndex);
    } else {
      setHideSelecedFlightDetails(false);
    }
  };

  const setHideContinueBtnFnc = (data: any) => {
    if (isDesktopView) {
      if (data) {
        setHideContinueBtn(true);
      } else {
        setHideContinueBtn(false);
      }
    }
  };

  return (
    <>
      <div className={styles.routeFlightContainer}>
        {flightDetails?.map((data: any, index: number) => (
          <div className={styles.departReturnContainer} key={index}>
            <div
              className={`global-content-margin ${styles.redemMilesContainer}`}
            >
              <div>
                {data?.journeyType === "Multi-City" ? (
                  <span className={styles.redeemMileDepartArrtLable}>
                    {"Flight" + " " + (index + 1) + " - " + "Depart"}
                  </span>
                ) : (
                  <span className={styles.redeemMileDepartArrtLable}>
                    {data?.triptype === "departure" ? "Depart" : "Return"}
                  </span>
                )}
              </div>

              <div className={styles.locationLabelCode}>
                <span>
                  {data?.from?.cityName}
                  <span> ({data?.from?.airportCode})</span>
                </span>
                <span> - </span>
                <span>
                  {data?.to?.cityName}
                  <span> ({data?.to?.airportCode})</span>
                </span>
              </div>

              {isDesktopView && (
                <div>
                  {!hideSelecedFlightDetails &&
                  data?.triptype === "departure" &&
                  data?.journeyType === "Return" ? (
                    <span className={styles.noOfMiles}>
                      {t(
                        "label_redemption_message_number_of_miles_for_one_passenger"
                      )}
                    </span>
                  ) : (
                    data?.triptype === "arrival" &&
                    data?.journeyType === "Return" && (
                      <span className={styles.noOfMiles}>
                        {t(
                          "label_redemption_message_number_of_miles_for_one_passenger"
                        )}
                      </span>
                    )
                  )}

                  {data?.journeyType === "One-way" && (
                    <span className={styles.noOfMiles}>
                      {t(
                        "label_redemption_message_number_of_miles_for_one_passenger"
                      )}
                    </span>
                  )}

                  {!hideSelecedFlightDetails &&
                  hideSelectedMulticity < index + 1 &&
                  data?.journeyType === "Multi-City" &&
                  data?.journeyType !== "Return" &&
                  data?.journeyType !== "One-way" ? (
                    <span className={styles.noOfMiles}>
                      {t(
                        "label_redemption_message_number_of_miles_for_one_passenger"
                      )}
                    </span>
                  ) : null}
                </div>
              )}
            </div>
            {/* // new figma changes Return and onw-way type */}
            {isDesktopView && (
              <>
                {!hideSelecedFlightDetails &&
                data?.triptype === "departure" &&
                data?.journeyType === "Return" ? (
                  <DateSelectionWidget iteration={index} flightDetails={data} />
                ) : (
                  data?.triptype === "arrival" &&
                  data?.journeyType === "Return" && (
                    <DateSelectionWidget
                      iteration={index}
                      flightDetails={data}
                    />
                  )
                )}

                {data?.journeyType === "One-way" && (
                  <DateSelectionWidget iteration={index} flightDetails={data} />
                )}
                {!hideSelecedFlightDetails &&
                hideSelectedMulticity < index + 1 &&
                data?.journeyType === "Multi-City" &&
                data?.journeyType !== "Return" &&
                data?.journeyType !== "One-way" ? (
                  <DateSelectionWidget iteration={index} flightDetails={data} />
                ) : null}

                <FlightCard
                  iteration={index}
                  flightDetails={data}
                  hideFlightsParent={hideFlightsParent}
                  setHideContinueBtnFnc={setHideContinueBtnFnc}
                />
              </>
            )}
          </div>
        ))}
        {hideContinueBtn && !disableContinueBtn && (
          <div className={styles.continueBtnDiv}>
            <button
              className={
                disableContinueBtn
                  ? styles.disbaledContinueBtn
                  : styles.enabledContinueBtn
              }
              disabled={disableContinueBtn}
              onClick={() => continueButton()}
            >
              <span className={styles.continueBtnLabel}>
                {t("button_reset_continue")}
              </span>
            </button>
          </div>
        )}
        {/* total miles exceeded popup */}
        {show && <ModalPopus />}
      </div>
    </>
  );
};

export default RouteFlightListing;
